import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import Helmet from '../../config/helmet';
import GoogleMaps from '../components/GoogleMaps';
import Container from '../components/UI/Container';

const { Title, Text } = Typography;

const Wrapper = styled(Container)`
  padding-top: 100px !important;
  padding-bottom: 2rem;
  height: calc(100vh - 208.17px);

  @media (max-width: 992px) {
    height: 100%;
  }
`;

const Content = styled.div`
  ul {
    margin: 1.5rem !important;

    li {
      list-style: disc;
    }
  }

  @media (min-width: 992.98px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1.5rem;
  }
`;

export default () => (
  <Wrapper>
    <Helmet title='Reservation Policy' />

    <Title>Reservation Policy</Title>
    <Content>
      <ul>
        <li>
          <Text>
            All reservations must be done at least one (1) week before. A 50% downpayment of total room bill must be
            made within seven (7) days of confirmation and balance must be settled upon check in.
          </Text>
        </li>
        <li>
          <Text>All reservations are subject to the cancellation policy of the resort.</Text>
        </li>
      </ul>
      <GoogleMaps isMarkerShown />
    </Content>
  </Wrapper>
);
